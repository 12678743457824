import './App.css';
import React from 'react';
import Board from './board.js';

export default function App() {
  return (
    <Board />
  );
}

