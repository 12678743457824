import React from 'react';
import { useState } from 'react';

export default function Board() {
  var black = "#000000";
  var grey = "#808080";
  var red = "#FF0000";
  var nbsp = "\u00A0";
  var [turn, changeTurn] = useState(1);
  /*
   * An array of `squares` is returned, and the `setSquare` function updates the array.
   * Therefore to update the tiles you must create a new array with the updated values
   * and then pass that into setSquare.
   */
  var [squares, setSquares] = useState(Array(9).fill(nbsp));
  var [colours, changeColours] = useState(Array(9).fill(grey));
  var [gameOver, setGameOver] = useState(false);
  var [status, setStatus] = useState("Current Turn: " + ( turn ? "X" : "O" ));
  var winningLines = [[0, 1, 2], [3, 4, 5], [6, 7, 8], [0, 3, 6], [1, 4, 7], [2, 5, 8], [0, 4, 8], [6, 4, 2]];

  const updateBoard = ( squareNum ) => {
    /* The updating of the hooks are scheduled after the execution of the event handle */
    if( colours[squareNum] === grey && !gameOver ) {
      var newArr = squares.slice();
      if( turn === 1 ) {
        newArr[squareNum] = "X";
        console.log("Player 1 (X) Has Played...");
        changeTurn(2);
      } else {
        newArr[squareNum] = "O";
        console.log("Player 2 (O) Has Played...");
        changeTurn(1);
      }
      setSquares(newArr);

      let newColours = colours.slice();
      newColours[squareNum] = black;
      changeColours(newColours);

      /* Check for winner: */
      let player = ( turn === 1 ? "X" : "O" );
      let winningLine = null;
      for( let i = 0; i < winningLines.length; i++ ) {
        let win = true;
        for( let j = 0; j < 3; j++ ) {
          if( newArr[winningLines[i][j]] !== player ) {
            win = false;
            break;
          }
        }
        if( win ) {
          winningLine = i;
          break;
        }
      }

      if( winningLine !== null ) {
        console.log(player + " Has Won!!");
        for( let j = 0; j < 3; j++ ) {
          newColours[winningLines[winningLine][j]] = red;
        }
        changeColours(newColours);
        setStatus("Winner: " + player);
        setGameOver(true);
      } else {
        let draw = true;
        for( let i = 0; i < squares.length; i++ ) {
          if( newColours[i] === grey ) { draw = false; }
        }
        if( draw ) { 
          console.log("Draw!");
          setStatus("DRAW!");
          setGameOver(true);
        }
        else { setStatus("Current Turn: " + ( player !== "X" ? "X" : "O" )); }
      }
    }
  }

  const onHoverHandle = (squareNum) => {
    if( !gameOver && colours[squareNum] !== black ) {
      console.log("Hovered On Tile " + squareNum);
      let newSquares = squares.slice();
      for( let i = 0; i < 9; i++ ) {
        if( i === squareNum ) { newSquares[squareNum] = (turn === 1 ? "X" : "O"); }
        else { colours[i] === grey && ( newSquares[i] = nbsp ); }
      }
      
      setSquares(newSquares);
    }
  }
  const offHoverHandle = (squareNum) => {
    if( !gameOver && colours[squareNum] === grey ) {
      console.log("Hovered Off Tile " + squareNum);
      let newSquares = squares.slice();
      newSquares[squareNum] = nbsp;
      setSquares(newSquares);
    }
  }

  const resetGame = () => {
    console.log("Game Reset!");
    setSquares(Array(9).fill(nbsp));
    changeColours(Array(9).fill(grey));
    setGameOver(false);
    changeTurn(1);
    setStatus("Current Turn: X");
  }

  return (
    <div container className="parent col-p-12" >
      <div className="board row col-w-8 col-p-12">
        <div className="board-row row col-p-12">
          <Square value={squares[0]} squareNum={0} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[0]} />
          <Square value={squares[1]} squareNum={1} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[1]} />
          <Square value={squares[2]} squareNum={2} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[2]} />
        </div>
        <div className="board-row row col-p-12">
          <Square value={squares[3]} squareNum={3} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[3]} />
          <Square value={squares[4]} squareNum={4} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[4]} />
          <Square value={squares[5]} squareNum={5} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[5]} />
        </div>
        <div className="board-row row col-p-12">
          <Square value={squares[6]} squareNum={6} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[6]} />
          <Square value={squares[7]} squareNum={7} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[7]} />
          <Square value={squares[8]} squareNum={8} clickHandle={updateBoard} onHoverHandle={onHoverHandle} offHoverHandle={offHoverHandle} colour={colours[8]} />
        </div>
      </div>
      <div className="status row col-w-4 col-p-12" >
          <h1 className="status-text row col-p-12" >{status}</h1>
          <button className="reset-button row col-p-12" onClick={() => resetGame()}>Start Again</button>
      </div>
    </div>
  );
}

function Square({ value, squareNum, clickHandle, onHoverHandle, offHoverHandle, colour }) {
  return (
    <button className="square square-text col-p-4" onClick={() => clickHandle( squareNum )} onMouseOver={() => onHoverHandle( squareNum )} onMouseOut={() => offHoverHandle( squareNum )} style={{ color: colour }} >{value}</button>
  );
}

